import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import Loading from '@/components/widgets/Loading.vue'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      status: { required }
    },
  },
  data() {
    return {
      loading: false,
      drawerOpened: false,
      status_list: [
        { text: 'รับสินค้า', value: 'accept' },
        { text: 'ไม่รับสินค้า', value: 'reject' }
      ],
      form: {
        _id: null,
        status: null,
        note: null
      }
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
      }
    }
  },
  methods: {
    resetForm () {
      this.form._id = null
      this.form.status = null
      this.form.note = null

      this.$nextTick(() => {
        this.$v.$reset();
      })
    },
    formBuilder(obj) {
      this.form._id = obj.data?.uuid ?? ''
      this.form.status = obj.data?.status ?? ''
      this.form.note = obj.data?.note ?? ''

      this.$v.form.$touch()
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    },
    async onSubmitForm() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.loading = true
      const formData = new FormData()
      formData.append('status', this.form.code)
      formData.append('note', this.form.name)

      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
          `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          showCancelButton: false,
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          showCloseButton: true,
      }).then(result => {
        this.onCloseDrawer()
      })
    }
  }
}
