import Vue from "vue"
import MenuList from './widgets/widget-stockin-menu'
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import { STOCKIN_TYPE } from '../../../../mixins/enum'
import Receive from './widgets/widget-stockin-receive'
import Count from './widgets/widget-stockin-count'
import Quality from './widgets/widget-stockin-quality'
import Store from './widgets/widget-stockin-store'
import Info from './widgets/widget-stockin-detail'
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    MenuList,
    'stockin-receive': Receive,
    'stockin-count': Count,
    'stockin-quality': Quality,
    'stockin-store': Store,
    'stockin-info': Info,
    Paginate
  },
  data: function() {
    return {
      outside: null,
      loading: false, 
      created: {
        start: null,
        end: null
      },
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { 
          key: 'date', 
          label: 'วันที่', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          class: 'v-top' 
        },
        { 
          key: 'product_code', 
          label: 'รหัสสินค้า', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'v-top' 
        },
        { 
          key: 'product_name', 
          label: ' ชื่อสินค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'v-top' 
        },
        { 
          key: 'cost', 
          label: 'ต้นทุน/หน่วย', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right v-top' 
        },
        { 
          key: 'qty', 
          label: 'จำนวน', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right v-top' 
        },
        { 
          key: 'nettotal', 
          label: 'ยอดรวมสุทธิ', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right v-top' 
        },
        { 
          key: 'action', 
          label: '#', 
          class: 'text-right v-top',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
        }
      ],
      items: [
        {
          uuid: '123456',
          date: new Date(),
          code: 'P-001',
          name: 'เหล็ก 1',
          type: 'สี่งซื้อ',
          receive: 'รับสินค้า',
          count: 1,
          count_note: 'สินค้าขาด 1 ชิ้น',
          quality: 'ผ่าน',
          quality_note: '',
          warehouse_code: 'w-123',
          warehouse: 'คลัง 1',
          store: 'ห้องเก็บ 1',
          cost: 2500,
          qty: 2,
          nettotal: 5000
        },
        {
          uuid: '123477',
          date: new Date(),
          code: 'P-002',
          name: 'เหล็ก 2',
          type: 'รับคืน',
          receive: 'reject',
          count: 2,
          count_note: '',
          quality: 'ไม่ผ่าน',
          quality_note: 'สินค้าชำรุด',
          warehouse_code: 'w-002',
          warehouse: 'คลัง 2',
          store: 'ห้องเก็บ 1',
          cost: 1000,
          qty: 2,
          nettotal: 2000
        }
      ],
      code: null,
      name: null,
      opened: {
        receive: false,
        count: false,
        quality: false,
        store: false,
        info: false
      }
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    handleReceiveEvent(e) {
      if (e.status) {
        this.opened.receive = false
      }
    },
    handleCountEvent(e) {
      if (e.status) {
        this.opened.count = false
      }
    },
    handleQualityEvent(e) {
      if (e.status) {
        this.opened.quality = false
      }
    },
    handleStoreEvent(e) {
      if (e.status) {
        this.opened.store = false
      }
    },
    handleInfoEvent(e) {
      if (e.status) {
        this.opened.info = false
      }
    },
    menuselection(e) {
      if (e.menu === STOCKIN_TYPE.RECEIVE && e.status) {
        const item = _.find(this.items, { 'uuid': e.id })
        this.code = item.code
        this.name = item.name
        this.opened.receive = !this.opened.receive
      } else if (e.menu === STOCKIN_TYPE.COUNT && e.status) {
        const item = _.find(this.items, { 'uuid': e.id })
        this.code = item.code
        this.name = item.name
        this.opened.count = !this.opened.count
      } else if (e.menu === STOCKIN_TYPE.QUALITY && e.status) {
        const item = _.find(this.items, { 'uuid': e.id })
        this.code = item.code
        this.name = item.name
        this.opened.quality = !this.opened.quality
      } else if (e.menu === STOCKIN_TYPE.STORE && e.status) {
        const item = _.find(this.items, { 'uuid': e.id })
        this.code = item.code
        this.name = item.name
        this.opened.store = !this.opened.store
      } else if (e.menu === STOCKIN_TYPE.INFO && e.status) {
        const item = _.find(this.items, { 'uuid': e.id })
        this.code = item.code
        this.name = item.name
        this.opened.info = !this.opened.info
      }
    },
    createBarcode() {
    },
    onInitData() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
