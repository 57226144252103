// import Produces from './produces-c3.vue'
// import Formula from './formula.vue'
import Drawer from '@/components/drawers'
import Loading from '@/components/widgets/Loading.vue'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    }
  },
  components: {
    Loading,
    Drawer
    // Produces,
    // Formula
  },
  data() {
    return {
      loading: false,
      drawerOpened: false,
      items: {
        uuid: '123477',
        date: new Date(),
        code: 'P-002',
        name: 'เหล็ก 2',
        type: 'รับคืน',
        receive: 'reject',
        count: 2,
        count_note: '',
        quality: 'ไม่ผ่าน',
        quality_note: 'สินค้าชำรุด',
        warehouse_code: 'w-002',
        warehouse: 'คลัง 2',
        store: 'ห้องเก็บ 1',
        warehouse_note: '',
        cost: 1000,
        qty: 2,
        price: 2000,
        lot: '001',
        description: 'รายละเอียด'
      }
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
        if (this.id) {
            this.onInitData()
        }
      }
    }
  },
  methods: {
    async onInitData() {
      this.loading = true
      const param = {
        _id: this.id
      }
      const result = await this.$store.dispatch('Products/get', param, { root: true })
      this.loading = false
      if (result.status === 200) {
        this.items = result.data
      } else {
        this.onExceptionHandler()
      }
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.items = {}
      this.$emit('close-event', { 
        status: true
      })
    }
  }
}